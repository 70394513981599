import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import colors from "styles/colors";
import history from "services/history";
import { NavLink as RNavLink } from "react-router-dom";

const Container = styled.div`
  width: 150px;
  max-width: 150px;
  height: 100%;
  box-shadow: 4px 0px 5px -3px rgba(0, 0, 0, 0.1);
  display: ${({ connected }) => {
    return connected ? "flex" : "none";
  }};
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 24px;
  color: ${colors.playgroundGreen};
  text-align: center;
`;

const Logout = styled.button`
  border: none;
  background: white;
  font-weight: bold;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;
const Links = styled.div`
  display: flex;
  padding-left: 20px;
  margin-top: 50px;
  flex-direction: column;
  flex: 1;
  & > * {
    margin-bottom: 15px;
  }
`;

const NavLink = styled(RNavLink)`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
  &.active {
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
`;

class LeftNav extends React.Component {
  render() {
    return (
      <Container connected={this.props.connected}>
        <Header>playground</Header>
        <Logout onClick={this.props.logout}>Logout</Logout>

        <Links>
          <NavLink to="/categories">Categories</NavLink>
          <NavLink to="/activities">Activities</NavLink>
          <NavLink to="/users">Users</NavLink>
          <NavLink to="/first_users">First users</NavLink>
          <NavLink to="/users_info">Users Info</NavLink>
          <NavLink to="/referrer_users">Referrer users</NavLink>
          <NavLink to="/access_codes">Access codes</NavLink>
          <NavLink to="/push_notifications">Push notifications</NavLink>
          <NavLink to="/admins">Admins</NavLink>
          <NavLink to="/london">London</NavLink>
          <NavLink to="/paris">Paris</NavLink>
          <NavLink to="/dubai">Dubai</NavLink>
          <NavLink to="/madrid">Madrid</NavLink>
          <NavLink to="/new_york">New York</NavLink>
          <NavLink to="/app_settings">App settings</NavLink>
        </Links>
      </Container>
    );
  }
}

const mapStateToProps = state => ({ connected: !!state.auth.token });
const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch({ type: "LOGOUT" });
    history.push("/login");
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(LeftNav);
