import React, { Component } from "react";
import { Centered } from "components/layout";

class Home extends Component {
  state = {};
  render() {
    return (
      <Centered style={{ height: "100%" }}>
        <h1>Welcome to the Playground Admin Tool</h1>
        <p>Use the links on the left to navigate</p>
      </Centered>
    );
  }
}

export default Home;
