// @flow
import {
  _get,
  _post,
  _put,
  _delete,
  _patch,
  _upload,
  _update
} from "./methods";
import places from "./places";
import type { Activity } from "./types";

export default {
  auth: {
    login: (p: any) => _get("/admin/auth/login", p),
    signup: (p: any) => _post("/admin/auth/signup", p)
  },
  administrators: {
    invite: (p: any) => _post("/admin/administrators", p),
    list: () => _get("/admin/administrators")
  },
  first_users: {
    invite: (p: any) => _post("/admin/invite", p),
    list: () => _get("/admin/invite/list/first_users"),
    delete: ({ user_id }: any) =>
      _delete(`/admin/invite/delete/first_users/${user_id}`),
  },
  users_info: {
    list: () => _get("/admin/users/info"),
  },
  access_codes: {
    create: (p: any) => _post("/admin/invite/code", p),
    list: () => _get("/admin/invite/code/list"),
    update: ({ id, ...rest }: any) =>
      _put(`/admin/invite/code/${id}`, rest),
    delete: ({ code_id }: any) =>
      _delete(`/admin/invite/code/${code_id}`),
  },
  push_notifications: {
    create: (p: any) => _post("/admin/scheduled/notification", p),
    list: () => _get("/admin/scheduled/notifications/list"),
    delete: ({ push_id }: any) =>
      _delete(`/admin/scheduled/notification/${push_id}`),
  },
  activities: {
    list: (): Promise<Activity> => _get("/admin/activities"),
    createActivity: ({ file, ...rest }: any) =>
      _upload("/admin/activity", file, "image", rest),
    deleteActivity: ({ id }: any) => _delete(`/admin/activity/${id}`),
    updateActivity: ({ id, file, name }: any) =>
      _update(`/admin/activity/${id}`, file, "file", { name })
  },
  categories: {
    list: () => _get("/admin/categories"),
    create: (p: any) => _post("/admin/categories", p),
    delete: ({ category_id }: any) =>
      _delete(`/admin/categories/${category_id}`),
    setDefaultActivities: ({ category_id, ...rest }: any) =>
      _put(`/admin/categories/${category_id}/activities`, rest)
  },
  places,
  users: {
    reported: {
      list: () => _get("/admin/users/reported")
    },
    reports: {
      get: ({ user_id }: any) => _get(`/admin/users/${user_id}/reports`)
    },
    ban: ({ user_id }: any) => _post(`/admin/users/${user_id}/ban`),
    unban: ({ user_id }: any) => _post(`/admin/users/${user_id}/unban`)
  },
  app_settings: {
    list: () => _get("/admin/settings"),
    update: d => _patch("/admin/settings", d)
  },
  referrer_users: {
    list: () => _get("/admin/invite/change_count"),
    update: ({ id, ...rest }: any) =>
      _put(`/admin/invite/change_count/${id}`, rest),
    update_all: ( rest: any) => {
      _post('/admin/invite/change_count', rest)
    }
  },
};
